import { Component, inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { TallyService } from '../shared/services/tally.service';
import { CreativesPresenter } from './presenter/creatives.presenter';
import { CreativesSideEffect } from './presenter/creatives.sideEffects';
import { CreativesViewEvents } from './presenter/creatives.viewEvents';
import { CreativesViewState } from './presenter/creatives.viewState';

@Component({
  selector: 'app-creatives',
  standalone: true,
  templateUrl: './creatives.component.html',
  styleUrls: ['./creatives.component.scss'],
  providers: [CreativesPresenter],
})
export class CreativesComponent extends BaseComponent<
  CreativesPresenter,
  CreativesViewState,
  CreativesViewEvents,
  CreativesSideEffect
> {
  public presenter: CreativesPresenter = inject(CreativesPresenter);

  private _tallyService: TallyService = inject(TallyService);

  private _activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  public onInit(): void {
    this._tallyService.loadWidget();

    this._activatedRoute.queryParams.subscribe({
      next: (params: Params) => {
        this.presenter.emitViewEvent({
          type: 'Init',
          productId: params.id,
          productName: params.name,
        });
      },
    });
  }
}
