import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { environment } from 'src/environments/environment';
import { CreativesSideEffect } from './creatives.sideEffects';
import { CreativesViewEvents } from './creatives.viewEvents';
import { CreativesViewState } from './creatives.viewState';

@Injectable()
export class CreativesPresenter extends BasePresenter<
  CreativesViewState,
  CreativesViewEvents,
  CreativesSideEffect
> {
  protected defaultViewState(): CreativesViewState {
    return {
      productId: '',
      productName: '',
      tallyUrl: '',
    };
  }

  protected onViewEvent(event: CreativesViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.updateViewState({
          ...this.viewState,
          tallyUrl: `https://tally.so/embed/${environment.CREATIVES_TALLY_FORM_ID}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&productId=${event.productId}&productName=${event.productName}&merchantId=${user.id}`,
        });
        break;
      }
    }
  }
}
