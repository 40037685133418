<div class="tally-form">
  <iframe
    [attr.data-tally-src]="viewState.tallyUrl"
    loading="lazy"
    width="100%"
    height="1150"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    title="طلب صور وفيديوهات خاصة بك للتسويق"
  ></iframe>
</div>
